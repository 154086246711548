export default {
   "url":"",
    "edoctusUrl":"",
    "version":18,
    "edocutusDocumentTypeId":7,
    "edocutusDatabase":"DHS General",
    "secretPhrase": "2348hsulhdfhgulewnrflksfd",
    "reportUrl":"https://app.powerbigov.us/groups/me/apps/63a4c892-efc7-4e80-b769-7d23a7e05eda/reports/4bc5a91c-4812-42f3-bb79-647cdf7adb53/ReportSection?ctid=5ec1d8f0-cb62-4000-b327-8e63b0547048",
    "VehSrchStaticText":"No Vehicle Search History found for the entered Reimbursement Period. User is ineligible for Mileage Expense Reimbursement.",
    "logoutUrl":"https://trips.dhs.arkansas.gov/",
	"cvtUrl":"https://uru.dhhs.arkgov.net/ofa/CVT/",
	"CheckMFA": "YES"
}